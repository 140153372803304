// src/Login.tsx
import React, { useState, useEffect } from 'react';
import NavigationBar from './components/NavigationBar';
import CreateAccount from './components/CreateAccount';
import Login from './components//Login';
import Dashboard from './components/Dashboard';
import Footer from './components/Footer';
import { auth, db } from './backend/firebase';
import firebase from 'firebase/compat/app';
import { CSSTransition, TransitionGroup } from 'react-transition-group';


const Entry: React.FC = () => {
  const [view, setView] = useState<string>('');
  const [user, setUser] = useState<firebase.User | null>(null);
  const [userData, setUserData] = useState({
    userAddress: null as string | null,
    rentAmount: null as number | null,
    rentPaid: null as boolean | null,
    dueDate: null as Date | null,
    isAdmin: null as boolean | null
  });

  function retrieveUserData(user: firebase.User) {
    const userInfoRef = firebase.database().ref('users/' + user.uid);
    userInfoRef.on('value', (snapshot) => {
      const userFbData = snapshot.val();
      console.log('User data retrieved:', userFbData);

      if (userFbData) {
        setUserData((prevData) => ({
          ...prevData,
          userAddress: userFbData.address,
          isAdmin: userFbData.isAdmin
        }));

        if (userFbData.address) {
          const addressInfoRef = firebase.database().ref('addresses/' + userFbData.address);
          addressInfoRef.on('value', (snapshot) => {
            const addressFbData = snapshot.val();
            console.log('Address data retrieved:', addressFbData);

            if (addressFbData) {
              setUserData((prevData) => ({
                ...prevData,
                rentAmount: addressFbData.rentAmount,
                dueDate: new Date(addressFbData.dueDate),
                rentPaid: addressFbData.rentPaid
              }));
            }
          });
        }
      }
    });
    return true;
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      try {
        if (user) {
          await user.reload();
          if (user.emailVerified) {
            setUser(user);
            // Retrieve information about user
            if(retrieveUserData(user)) {
               setView('dashboard');
            }
          } else {
            console.log('User is not verified');
          }
        } else {
          setView('login');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <>
      <div className='entry-container'>
        <NavigationBar navText={view !== 'dashboard' ? 'Your rental payments made easy' : 'Welcome home 🏠'} 
                      setView={setView} 
                      setUser={setUser} 
                      isAdmin={userData.isAdmin}
        />    
        {view === 'login' && (
              <Login setView={setView} setUser={setUser} />
        )}
        {view === 'create' && (
            <CreateAccount setView={setView} setUser={setUser} />
        )}
        {view === 'email-verification' && (
          <div className="login-container">
            <div className='text-form'>
              <h1>Email verification was sent, check your email inbox.</h1>
            </div>
          </div>
        )}
        {view === 'dashboard' && (
          <Dashboard setView={setView} setUser={setUser} userData={userData} />
        )}
        <Footer />
      </div>
    </>
  );
};

export default Entry;
