// CreateAccountFormData.tsx

const createAccountFormData = [
    {
      id: "email",
      type: "email",
      label: "Email",
      placeholder: "Enter your email",
    },
    {
      id: "password",
      type: "password",
      label: "Password",
      placeholder: "Enter your password",
    },
    {
      id: "confirm-password",
      type: "password",
      label: "Confirm Password",
      placeholder: "Confirm your password",
    },
    {
      id: "address",
      type: "text",
      label: "Address",
      placeholder: "Enter your address",
    },
    {
      id: "phone",
      type: "tel",
      label: "Phone Number",
      placeholder: "Enter your phone number",
    },
  ];

  const loginFormData = [
    {
      id: "email",
      type: "email",
      label: "Email",
      placeholder: "Enter your email",
    },
    {
      id: "password",
      type: "password",
      label: "Password",
      placeholder: "Enter your password",
    },
  ];

  const paymentFormData = [
    {
      id: "currency",
      type: "text",
      label: "Amount",
      placeholder: "Enter payment amount",
    }
  ];
  
  export default {createAccountFormData, loginFormData, paymentFormData};