import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { auth, db } from '../backend/firebase';
import formData from './FormData';

type CreateAccountProps = {
    setView: React.Dispatch<React.SetStateAction<string>>;
    setUser: React.Dispatch<React.SetStateAction<firebase.User | null>>;
};

const CreateAccount: React.FC<CreateAccountProps> = ({ setView, setUser }) => {
    const [formValues, setFormValues] = useState<{ [key: string]: string }>({});

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormValues({
        ...formValues,
        [e.target.id]: e.target.value
      });
    };  

    const handleCreateAccountSubmit = async (event: React.FormEvent) => {
      event.preventDefault();
      // Handle create account logic here
      try {
        if (formValues.password !== formValues['confirm-password']) {
          alert('Passwords do not match');
          return;
        }
        const userCredential = await auth.createUserWithEmailAndPassword(formValues.email, formValues.password);
        const user = userCredential.user;
    
        if (user) {
          // Send verification email
          await user.sendEmailVerification();
          setView('email-verification');
          // Store additional user information in Realtime Database
          await db.ref('users/' + user.uid).set({
            email: user.email,
            address: formValues.address,
            phone: formValues.phone,
            loggedIn: false,
            ...(user.email === 'nrchristen@gmail.com' || user.email === 'mayraar001@live.com' 
                ? { isAdmin: true } : {isAdmin: false})
          });
          await db.ref('payments/' + user.uid).set({
            amountOwed: 0,
            paymentHistory: [],
            lastPayed: new Date().toISOString()
          });
          setUser(user);
        }
      } catch (error) {
        console.error('Sign Up Error:', error);
      }     
    };

    return (
      <div className="login-container">
        <form className="credential-form" onSubmit={handleCreateAccountSubmit}>
          <h2>Create Account</h2>
          {formData.createAccountFormData.map((field) => (
          <div className="form-group" key={field.id}>
            <label htmlFor={field.id}>{field.label}</label>
            <input
              type={field.type}
              id={field.id}
              placeholder={field.placeholder}
              value={formValues[field.id] || ''}
              onChange={handleChange}
              required
            />
          </div>
        ))}
          <button type="submit" className="login-button">Submit</button>
          <button type="button" className="switch-button" onClick={() => setView('login')}>Back to Login</button>
        </form>
      </div>
    )};

export default CreateAccount;