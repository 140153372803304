import React from 'react'
import logo from '../resources/ramirez_rentals_transparent.png'
import MenuButton from '../components/MenuButton';
import firebase from 'firebase/compat/app';

type NavigationBarProps = {
  navText: string; // Renamed prop for clarity
    setView: React.Dispatch<React.SetStateAction<string>>;
    setUser: React.Dispatch<React.SetStateAction<firebase.User | null>>;
    isAdmin: boolean | null;
};

const NavigationBar: React.FC<NavigationBarProps> = ({navText, setView, setUser, isAdmin}) => {
  return (
    <nav className='navbar'>
        <img src={logo} className='nav-logo' alt='ramirez-rentals' />
    <div className='nav-center'>
        <h1 className='nav-header'>{navText}</h1>
    </div>
    <MenuButton setView={setView} setUser={setUser} isAdmin={isAdmin} />
  </nav>
  )
}

export default NavigationBar