import React from 'react';
import Entry from './Entry';
import './resources/main.css';
import './resources/transitions.css'; // Define your CSS transitions here

function App() {
  return (
    <div className='app-container'>
      <Entry />
    </div>
  );
}

export default App;
