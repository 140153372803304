import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import { auth } from '../backend/firebase';
import formData from './FormData'

type LoginProps = {
  setView: React.Dispatch<React.SetStateAction<string>>;
  setUser: React.Dispatch<React.SetStateAction<firebase.User | null>>;
};

// Type guard to check if error is a Firebase auth error
const isFirebaseAuthError = (error: unknown): error is firebase.FirebaseError => {
  return typeof error === 'object' && error !== null && 'code' in error;
};

const Login: React.FC<LoginProps> = ({ setView, setUser}) => {
  const [errorMessage, setError] = useState<string | null>(null);
  const [formValues, setFormValues] = useState<{ [key: string]: string }>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.id]: e.target.value
    });
  };  

  const handleLoginSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null); 
    // Handle login logic here
    try {
      const userCredential = await auth.signInWithEmailAndPassword(formValues.email, formValues.password);
      const user = userCredential.user;

      if (user) {
        if (user.emailVerified) {
          setUser(user);
        } 
        else {
          setView('email-verification');
          auth.signOut();
        }
      } else {
        alert('No user found. Please sign up first.');
      }
    } catch (error) {
      if (isFirebaseAuthError(error)) {
        if (error.code === 'auth/invalid-credential') {
          setError('Invalid email or password. Please try again.');
        }       
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
      alert(errorMessage);
    }
  };

  return (
    <div className="login-container">
      <form className="credential-form" onSubmit={handleLoginSubmit}>
        <h2>Login</h2>
        {formData.loginFormData.map((field) => (
        <div className="form-group" key={field.id}>
            <label htmlFor={field.id}>{field.label}</label>
            <input
              type={field.type}
              id={field.id}
              placeholder={field.placeholder}
              value={formValues[field.id] || ''}
              onChange={handleChange}
              required
            />
          </div>
        ))}
        <button type="submit" className="login-button">Login</button>
        <button type="button" className="switch-button" onClick={() => setView('create')}>Create Account</button>
      </form>
    </div>
  );
};

export default Login;
