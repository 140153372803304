// EllipsesMenuButton.tsx
import React, { useState, MouseEvent } from 'react';
import firebase from 'firebase/compat/app';
import { auth, db } from '../backend/firebase';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import '../resources/menuButton.css';

type MenuButtonProps = {
    setView: React.Dispatch<React.SetStateAction<string>>;
    setUser: React.Dispatch<React.SetStateAction<firebase.User | null>>;
    isAdmin: boolean | null;
};

const MenuButton: React.FC<MenuButtonProps> = ({setView, setUser, isAdmin}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        auth.signOut();
        handleClose();
        setView('login');
    }

    const reAuthenticateAndDeleteUser = async (password: string) => {
        const user = auth.currentUser;
        if (user) {
        const credential = firebase.auth.EmailAuthProvider.credential(
            user.email!,
            password
        );
        try {
            await db.ref('users/' + user.uid).remove();
            console.log('User data deleted successfully');
            // Re-authenticate the user
            await user.reauthenticateWithCredential(credential);
            // Delete the user
            await user.delete();
            console.log('User deleted successfully');
            setView('login')
        } catch (error) {
            console.error('Error deleting user:', error);
        }
        } else {
        console.log('No user is currently signed in');
        }
    };

    return (
        <div className='menu-button'>
            <IconButton
                className="custom-icon-button"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                classes={{ paper: "custom-menu" }}
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>Account</MenuItem>
                <MenuItem onClick={handleClose}>Payments</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                {isAdmin ? <MenuItem onClick={(event) => reAuthenticateAndDeleteUser('password')}>Admin: Delete account + data</MenuItem> : null}
            </Menu>
        </div>
    );
};

export default MenuButton;
