import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/compat/app';
import Payment from '../components/Payment';
import { auth, db } from '../backend/firebase';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

type DashboardProps = {
    setView: React.Dispatch<React.SetStateAction<string>>;
    setUser: React.Dispatch<React.SetStateAction<firebase.User | null>>;
    userData: { 
      userAddress: string | null,
      rentAmount: number | null,
      rentPaid: boolean | null,
      dueDate: Date | null,
      isAdmin: boolean | null
    };
};

const Dashboard: React.FC<DashboardProps> = ({ setView, setUser, userData }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);
  const backEndUrl = process.env.REACT_APP_BACKEND_URL_DEV || '';
  const [showPaymentComponent, setShowPaymentComponent] = useState<boolean>(false);
  const [showAnalyticsComponent, setShowAnalyticsComponent] = useState<boolean>(false);
  const [stripeClientSecret, setClientSecret] = useState<string>('');
  const [paymentCompleted, setPaymentCompleted] = useState<boolean>(false);

  const payString0 = `Rent amount: $${(userData.rentAmount ? (userData.rentAmount / 100).toFixed(2) : "0.00")} 
                      ${userData.rentPaid ? 'has been paid!' 
                        : `is due by ${new Date().getMonth() + 2}/1/${new Date().getFullYear()}`}`;
  const payString1 = `Payment Complete!`;

  const options = {
    clientSecret: stripeClientSecret,
  };
  
  const setUpPayment = async (e: React.MouseEvent<HTMLButtonElement>, amount: number | null) => {
    e.preventDefault();
    try {
        const response = await fetch(`${backEndUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ amount: amount, receipt_email: auth.currentUser?.email }), // Amount in cents
        });

        if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(errorResponse.error);
        }
        const { secret } = await response.json();
        setClientSecret(secret);
        setShowPaymentComponent(true);  
    } 
    catch (error) {
        console.log((error as Error).message || 'An unknown error occurred');
    }
};
  return (
    <div className='dashboard-container'>        
        <div className='dashboard payment'>
          {!showPaymentComponent ? (
            <>
              <label>{paymentCompleted ? payString1 : payString0}</label>
              <button className='payment-button' onClick={(e) => setUpPayment(e, userData.rentAmount)}>Make a payment</button>
            </>
          ): (
            <Elements stripe={stripePromise} options={options}>
              <label>Payment Information</label>        
              <Payment setUser={setUser} setShowPaymentComponent={setShowPaymentComponent} 
                       setPaymentCompleted={setPaymentCompleted} address={userData.userAddress}/>
            </Elements>
          )}
        </div>
        {/* <div className='dashboard analytics'>
          {!showAnalyticsComponent ? (
              <button className='dashboard-form-payment-button' onClick={() => setShowAnalyticsComponent(true)}>Analytics</button>
          ): (
            <button className='dashboard-form-cancel-button' onClick={() => setShowAnalyticsComponent(false)}>Cancel</button>                
          )}
        </div> */}
    </div>
  )
}

export default Dashboard